import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/home/banner.png'
class Species360Container extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Case Study"
                    bannerImage={banner}
                    text="Journey to Continuous Integration & Development using GTEN Test Engineering Capabilities" />
                <Container fluid>
                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>The</span> Customer
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    The Customer Species360 facilitates international collaboration in the
                                    collection, sharing and analysis of knowledge on wildlife.
                                    Together, Species360 and their members help to improve animal
                                    care and welfare, and inform species conservation.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Information
                                    </h3>
                                <Container className="pt-3">
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Platform Scale</h3>
                                            <p>Global SaaS platform used by 500+ zoos, aquariums and private animal
                                                 conservationist across the globe</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>Duration</h3>
                                            <p>24 months</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Delivery Methodology</h3>
                                            <p>SAFE (Scaled Agile Framework)</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>GTEN Role</h3>
                                            <p>Independent Test Partner</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Challenge
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                • Rapid application development using Agile with no automated checks in place.
                                    No continuous integration, testing and deployment process
                            </p>
                                <p className="industries-paragrapgh">
                                • Custom .Net control library using Telerik controls.
                            </p>
                                <p className="industries-paragrapgh">
                                • Developers validate new builds in dev environment and push to production.
                            </p>
                                <p className="industries-paragrapgh">
                                • High leakage of defects at every stage of software engineering.
                            </p>
                                <p className="industries-paragrapgh">
                                • Lack of defined process for test documentation, test planning or defects triage.
                            </p>
                                <p className="industries-paragrapgh">
                                • Dedicated environment for testing wasn’t available.
                            </p>
                                <p className="industries-paragrapgh pt-3">
                                • No continuous integration, testing and deployment process
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>GTEN</span> Solution
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    Testing COE team of GTEN Technologies implemented a solution which consisted of
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Test Process Consulting</b>   Designed and successfully i
                                    mplemented a Test Methodology to be used
                                    across the client functional and performance testing. These methodologies detail out
                                    the approach to be followed for testing a release including identifying testable requirements
                                     for functional and performance
                                    testing, designing test suites, test execution approach
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Domain-Focused Functional Testing </b>  Extensive focus given
                                     on domain by means of trainings conducted by business analysts. Before test cases are designed,
                                     requirements are discussed and analysed within the team for business understanding and end-user
                                      focus. Over 70% of all
                                    test cases written are focused on domain-specific business processes and user scenarios.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Test Automation </b>  Implemented a keyword-driven automation
                                     framework for regression test suite using MicroFocus Unified Functional
                                     Tester (UFT) to reduce time to market while increasing test coverage.
                                      Over 90% of regression tests have been automated.
                            </p>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Performance Testing </b>  Provide as-needed Performance Testing
                                    using WAPT to detect critical performance bottlenecks.
                            </p>
                            </Col>

                        </Row>

                    </Container>
                    <Container className="py-5">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Benefits</span> Achieved
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    The client benefits are categorized into the following areas:
                                </p>
                                
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Timelines</b>
                                </p>
                                <ul>
                                        <li> Continuous integration and deployment established as a process.</li>
                                        <li> Production release timelines reduced by 50% and defect slippage by 80%.</li>
                                        <li>Implemented pair-wise testing with developers to identify defects early.</li>
                                        <li>Collaborated with business analysts to find gaps at requirements stage.</li>
                                    </ul>
                                
                            </Col>
                            <Col>
                                
                                
                                <p>
                                    <b className="text-standard">Test Automation</b>
                                </p>
                                <ul>
                                        <li> Over 85% savings in regression testing costs.</li>
                                        <li> Overall resource requirements have been cut by 45% through the use of automation tools and frameworks.</li>
                                    </ul>
                            </Col>


                        </Row>
                    </Container>
                    <hr />
                </Container>

            </>
        )
    }
}

export default Species360Container