import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Species360Container from "../containers/Species360Container"

const Species360 = (props) => (
  <Layout 
  pageInfo={{ pageName: "Species 360" }} 
  styleClass="home-page" 
  location={'/species360/'}>
    <SEO title="Species 360" />
    <Species360Container />
  </Layout>
)

export default Species360
